

import {Component, Vue} from "vue-property-decorator";
import {BASE_API_URL} from "../../../config";
import Axios from "axios";
import DisplayFiles from "@/views/DisplayDocument.vue"

@Component({ components: { DisplayFiles} })
export default class CreditReportResponse extends Vue{
    public creditReport = [];
    public hasData = true;

    public async fetchCreditReportResponse() {
    try {
      this.$store.state.wemloLoader = true;
      let response = await Axios.get(
        BASE_API_URL + "creditReport/fetchCreditReportHistory",
        {
          params: { loanTxnId: this.$route.query.loanTxnId ,
          source: "superAdmin"}
        }
      );
      this.creditReport = response.data.creditReportRequest;
      if(this.creditReport.length>0)
      this.creditReport.reverse();
      else
      this.hasData = false;


    }
    catch(error){
        console.log(error);
    }
      this.$store.state.wemloLoader = false;

    }

    public showXmlFile(file) {
    this.$refs.dfd["setInitialValuesForAttachmentsAndComments"](
      null,
      [file],
      null
    );
  }





   public async mounted(){
    this.fetchCreditReportResponse();

   }

}
